<script>
import FormFieldInput from "@components/form-field-input";
import HeaderComponent from "@components/header";
import states from "@utils/states";

export default {
    name: "DealerRegisteration",
    components: {
        FormFieldInput,
        HeaderComponent,
    },
    data() {
        return {
            dealer: {
                name: null,
                shopName: null,
                state: null,
                address: null,
                password: null,
                phone: null,
                panNumber: null,
                gstNumber: null
            },
            states,
        };
    },
    methods: {
        async registerDealer() {
            try {
                const { isValid, message } = this.$utility.dealerValidation(
                    this.dealer
                );
                if (!isValid) {
                    return this.$utility.showErrorMessage(message);
                }

                const result = await this.$http.rawPost(
                    "common/register/dealer",
                    this.dealer
                );

                this.dealer = {};
                this.$utility.showSuccessMessage(result.message);
                this.$utility.goToPage("login");
            } catch (err) {
                this.$utility.showErrorMessage(err.message);
            }
        },
    },
};
</script>

<template lang="pug">
header-component
.container.px-4.my-8.text-center.mx-auto
    h2.text-center.text-4xl.mb-6.res-h1 Dealer/Manufacturer Registration
    .grid.grid-rows-1.grid-flow-col.gap-4.res-col
        .row-span-3.ml-auto.res-row
            form-field-input(
                inputType="email",
                placeholder="Enter email",
                attrinbute="email",
                v-model="dealer.email",
                :required="false",
                label="Email"
            )
        .row-span-3.mr-auto.res-row
            form-field-input(
                inputType="password",
                placeholder="Enter password",
                attrinbute="password",
                v-model="dealer.password",
                :required="false",
                label="Password"
            )
    h3.text-center.text-3xl.my-3.res-h1 Business Details
    .grid.grid-rows-1.grid-flow-col.gap-3.res-col.justify-center
        .row-span-3.ml-auto.res-row
            form-field-input(
                inputType="textonly",
                placeholder="Enter Business name",
                attrinbute="name",
                v-model="dealer.shopName",
                :required="true",
                label="Business Name"
            )
            form-field-input(
                inputType="select",
                placeholder="Select State",
                attrinbute="state",
                v-model="dealer.state",
                :required="true",   
                label="State",
                labelKey="state",
                valueKey="state",
                :items="states",
                :clearable="true"
            ) 
        .row-span-3.mr-auto.res-row
            form-field-input(
                inputType="alphatext",
                placeholder="Enter PAN number",
                attrinbute="panNumber",
                v-model="dealer.panNumber",
                :required="true",
                label="PAN Number",
                :minLength="10",
                :maxLength="10"
            )
            form-field-input(
                inputType="text",
                placeholder="Enter Address",
                attrinbute="address",
                v-model="dealer.address",
                :required="true",
                label="Address"
            )
        .row-span-3.res-row.mr-auto
            form-field-input(
                inputType="alphatext",
                placeholder="Enter GST number",
                attrinbute="gstNumber",
                v-model="dealer.gstNumber",
                :required="true",
                label="GST Number",
                :minLength="15",
                :maxLength="15"
            )
    h3.text-center.text-3xl.my-3.res-h1 Contact Person Details
    .grid.grid-rows-1.grid-flow-col.gap-4.res-col
        .row-span-3.ml-auto.res-row
            form-field-input(
                inputType="textonly",
                placeholder="Enter name",
                attrinbute="name",
                v-model="dealer.name",
                :required="true",
                label="Name"
            )
        .row-span-3.mr-auto.res-row
            form-field-input(
                inputType="textnumber",
                placeholder="Enter phone number",
                attrinbute="phone",
                v-model="dealer.phone",
                :required="true",
                label="Phone",
                :minLength="10",
                :maxLength="10"
            )
    .grid.grid-rows-1.grid-flow-col.gap-4.res-col
        .row-span-3.mx-auto.select-state.res-row 
            .text-center
                el-button(type="primary", @click="registerDealer") Register
</template>
